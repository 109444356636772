<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="1050">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">移动小节到</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <!-- <v-col cols="12" md="10" offset="1"> -->
                        <div class="content">
                            <div class="content-col col-line">
                                <v-data-table
                                    v-model="selected"
                                    :headers="topicHeaders"
                                    :items="topics"
                                    item-key="topic_id"
                                    class="elevation-0 row-pointer"
                                    :single-select="singleSelect"
                                    :hide-default-footer="true"
                                    @click:row="selectTopic"
                                >
                                    <template v-slot:item.name="{ item }">
                                        <div
                                            class="font-weight-medium"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                            <div class="content-col col-line">
                                <v-data-table
                                    v-model="selected"
                                    :headers="groupHeaders"
                                    :items="groups"
                                    item-key="group_id"
                                    class="elevation-0 row-pointer"
                                    :single-select="singleSelect"
                                    :hide-default-footer="true"
                                    @click:row="selectGroup"
                                >
                                    <template v-slot:item.name="{ item }">
                                        <div
                                            class="font-weight-medium"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-data-table>
                            </div>
                            <div class="content-col">
                                <v-data-table
                                    v-model="selected"
                                    :headers="courseHeaders"
                                    :items="courses"
                                    :page.sync="page"
                                    @page-count="pageCount = $event"
                                    item-key="cid"
                                    class="elevation-0 row-pointer"
                                    :single-select="singleSelect"
                                    :hide-default-footer="true"
                                    @click:row="selectCourse"
                                >
                                    <template v-slot:item.name="{ item }">
                                        <div
                                            class="font-weight-medium"
                                        >
                                            {{ item.name }}
                                        </div>
                                    </template>
                                </v-data-table>
                                <div v-show="courses.length > 10">
                                    <v-pagination
                                        v-model="page"
                                        :length="pageCount"
                                    ></v-pagination>
                                </div>
                            </div>
                        </div>
                    <!-- </v-col> -->
                </v-row>
                <v-card-actions>
                    <v-spacer class="mb-4"></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getAdminTopicList, getAdminGroupList, getAdminCourseList } from "@/api/admin";
import { getTypeColor } from '@/utils/common';

export default {
    name: "MoveSectionToDlg",

    data: function () {
        return {
            dialog: false,

            topicHeaders: [
                { text: "课程包", value: "name", align: "center", sortable: false },
            ],
            topics: [],
            
            groupHeaders: [
                { text: "分组", value: "name", align: "center", sortable: false },
            ],
            groups: [],

            courseHeaders: [
                { text: "课程", value: "name", align: "center", sortable: false },
            ],
            courses: [],
            page: 1,
            pageCount: 0,

            singleSelect: true,
            selected: [],
            
            params: "",
            newTopicId: "",
            newGroupId: "",
            newCourseId: "",
        };
    },
    computed: {},
    created() {},
    activated() {
    },
    mounted() {
    },
    watch: {},
    methods: {
        getTypeColor,
        loadData() {
            getAdminTopicList('PRIVATE').then((response) => {
                // console.log(response.data)
                this.topics = response.data
                
            }).catch(function (err) {
                console.log(err);
            });
        },
        openDlg(params) {
            this.params = params

            // 先clear一下
            this.topics = []
            this.groups = []
            this.courses = []
            this.newTopicId = ""
            this.newGroupId = ""
            this.newCourseId = ""
           
            this.loadData();

            this.dialog = true
        },
        async selectTopic(row) {
            // console.log(row)
            this.groups = []
            this.courses = []
            this.newGroupId = ""
            this.newCourseId = ""

            this.newTopicId = row.topic_id

            // 获取主题分组列表
            let response = await getAdminGroupList(row.topic_id)
            this.groups = response.data
        },
        async selectGroup(row) {
            // console.log(row)
            this.courses = []
            this.newCourseId = ""

            this.newGroupId = row.group_id

            let response = await getAdminCourseList(row.group_id)
            // console.log(response.data);
            this.courses = response.data;

            
        },
        selectCourse(row) {
            // console.log(row)
            this.newCourseId = row.cid

            this.$emit("submit", {
                sid: this.params.sid,
                cid: this.params.cid,
                newCourseId: this.newCourseId,
            })

            this.dialog = false
        }
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.content {
    width: 100%;
    margin: 10px 40px;
    border: 1px solid #eee;
    display: table;
    table-layout:fixed;
    .content-col {
        flex: 1;
        display: table-cell;
        &.col-line {
            border-right: 1px solid #eee;
        }
    }
    .content-col-title {
        font-size: 15px;
        font-weight: bold;
        padding-bottom: 5px;
    }
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
