<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="400">
            <v-card class="px-4">
                <v-card-title class="mb-3">
                    <span class="text-h4 font-weight-bold">课程码</span>
                    <v-spacer></v-spacer>
                    <v-icon size="24" class="btn-close" @click.stop="close">mdi-close</v-icon>
                </v-card-title>
                <div class="share-course-content">
                    <div class="left">
                        <v-otp-input
                            class="mb-3"
                            length="6"
                            v-model="code"
                            disabled
                        ></v-otp-input>
                        <div class="share-url">
                            <input id="shareUrl" type="text" :value="shareUrl" />
                            <!-- <div class="btn-reset-url">重置</div> -->
                            <v-btn
                                tile
                                dark
                                class="btn-copy-url btnCopy"
                                :loading="loading"
                                color="blue"
                                @click="loader = 'loading'"
                                data-clipboard-target="#shareUrl"
                            >
                                复制链接
                                <template v-slot:loader>
                                    <span class="custom-loader">
                                        <v-icon dark>mdi-check</v-icon>
                                    </span>
                                </template>
                            </v-btn>
                        </div>
                    </div>
                    <!-- <div class="right">
                        <v-img
                            :src="qrcode"
                            height="160"
                            width="160"
                            class="grey darken-4"
                        ></v-img>
                    </div> -->
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import Clipboard from 'clipboard';
import { getRandomNum } from "@/utils/util"
import { genClassroomCode } from "@/api/admin"

export default {
    name: "ShareCourseDlg",
    data() {
        return {
            dialog: false,

            code: "",
            shareUrl: "",
            qrcode: "",

            loader: null,
            loading: false,
        };
    },
    computed: {
    },
    created() {},
    activated() {
    },
    mounted() {
        this.init()
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]

            setTimeout(() => (this[l] = false), 1500)

            this.loader = null
        },
    },
    methods: {
        getRandomNum,
        init() {
            const btnCopy = new Clipboard('.btnCopy')
        },
        async openDlg(type, id) {
            let res = await genClassroomCode(type, id)
            this.code = res.data.code

            if (type == 'course') {
                this.shareUrl = "https://koocoding.com/classroom?cid=" + id
                // this.code = getRandomNum(100001,999999).toString()
            
            } else if (type == 'topic') {
                this.shareUrl = "https://koocoding.com/classroom?cid=" + id
                // this.code = getRandomNum(100001,999999).toString()
            }
            this.dialog = true
        },
        close() {
            this.dialog = false
        },
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}
.share-course-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px 40px 25px;
    .left {
        flex: 1;
    }
    .right {
        // flex: 1;
    }
    .course-num {
        font-weight: bold;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 10px;
        width: 320px;
        .text {
            font-size: 28px;
            letter-spacing: 2px;
        }
        .num {
            flex: 1;
            font-size: 72px;
            letter-spacing: 5px;
        }
    }
    .share-url {
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 320px;
        border: 1px solid #ccc;
        input{
            height: 40px;
            outline-style: none ;
            // border: 1px solid #ccc;
            padding: 8px 12px;
            font-size: 14px;
            flex: 1;
        }
        .btn-reset-url {
            height: 40px;
            padding: 8px 15px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            cursor: pointer;
        }
        .btn-copy-url {
            height: 40px;
            padding: 8px 15px;
            background-color: rgb(22, 132, 252);
            color: #fff;
            cursor: pointer;
            margin-right: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}
.btn-close {
    cursor: pointer;
}
</style>
