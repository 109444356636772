<template>
    <v-container fluid class="px-8">
        <!-- 课程基本内容和状态 -->
        <v-card class="course-info mb-12" elevation="2">
            <v-img
                :src="getImgUrl4Type(course.cover, course.course_type)"
                height="125"
                width="200"
                max-width="200"
                class="grey darken-4 me-5"
            ></v-img>

            <div class="course-desc">
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            课程名称：
                        </div>
                        <div class="text">{{ course.name }}</div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            课程说明：
                        </div>
                        <div class="text">{{ getText(course.desc, "暂无说明") }}</div>
                    </div>
                </div>
                <div class="desc-col one">
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            难度级别：
                        </div>
                        <div class="rating">
                            <v-rating
                                :value="course.level"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                dense
                                readonly
                            ></v-rating>
                        </div>
                    </div>
                    <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">
                            知识要点：
                        </div>
                        <div class="text">{{ course.points }}</div>
                    </div>
                    <!-- <div class="mb-2 desc-row">
                        <div class="font-weight-medium desc-name">状态：</div>
                        <div class="text">{{ course.status }}</div>
                    </div> -->
                </div>
            </div>
            <div class="operate" v-if="course.is_private">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1 darken-2"
                            icon
                            height="42"
                            width="42"
                            outlined
                            elevation="2"
                            small
                            color="green"
                            @click.stop="openShareCourseDlg('course', course.cid)"
                        >
                            <!-- <v-icon dark> mdi-share </v-icon> -->
                            <v-icon dark> mdi-qrcode </v-icon>
                        </v-btn>
                    </template>
                    <span>课程码</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="42"
                            width="42"
                            outlined
                            elevation="2"
                            small
                            color="purple"
                            @click.stop="openEditCourseDlg(course)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑课程</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1 mr-4"
                            icon
                            height="42"
                            width="42"
                            outlined
                            elevation="2"
                            small
                            color="red darken-1"
                            @click.stop="openConfirmDlg('delCourse', { cid: course.cid })"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除课程</span>
                </v-tooltip>
            </div>
            <div class="operate" v-else >
                <v-btn
                    tile
                    dark
                    color="light-green"
                    class="font-weight-bold text-body-1 mr-4"
                    @click.stop="openShareCourseDlg('course', course.cid)"
                >
                    <v-icon left> mdi-qrcode </v-icon>
                    课程码
                </v-btn>
            </div>
        </v-card>

        <!-- 增加小节按钮 -->
        <div class="add-btn-row">
            <div>
                <v-btn
                    tile
                    dark
                    color="purple"
                    class="font-weight-bold text-body-1"
                    @click.stop="openEditCourseSectionDlg()"
                    v-if="course.is_private"
                >
                    <v-icon left> mdi-plus-circle-outline </v-icon>
                    增加小节
                </v-btn>
            </div>
            <div>
                <!-- <v-btn
                    tile
                    dark
                    color="blue darken-1"
                    class="font-weight-bold text-body-1 mr"
                    @click.stop="openConfirmDlg('online')"
                >
                    <v-icon left> mdi-play </v-icon>
                    课程上线
                </v-btn>
                <v-btn
                    tile
                    dark
                    color="deep-orange darken-1"
                    class="font-weight-bold text-body-1 mr-0"
                    @click.stop="openConfirmDlg('offline')"
                >
                    <v-icon left> mdi-play </v-icon>
                    课程下线
                </v-btn> -->
            </div>
        </div>

        <!-- 当前课程小节列表 -->
        <v-data-table
            :headers="course.is_private ? sectionHeader : sectionHeaderReadonly"
            :items="sections"
            item-key="id"
            class="elevation-2 mb-12"
            :disable-pagination="true"
            :hide-default-footer="true"
            @click:row="clickRow"
        >
            <template v-slot:body="props">
                <draggable 
                    :list="props.items" 
                    tag="tbody"
                    @change="sectionListChange"
                    handle=".handle"
                >
                    <tr
                        v-for="(item, index) in props.items"
                        :key="index"
                        class="table-row"
                        @click.stop="clickRow(item)"
                    >
                        <!-- 顺序 -->
                        <td v-if="course.is_private"><v-icon class="handle"> mdi-sort </v-icon></td>
                        <!-- 序号 -->
                        <td>{{ index + 1 }}</td>
                        <!-- 类型 -->
                        <td class="font-weight-medium">
                            <v-chip
                                :color="getSectionTypeColor(item.type)"
                                dark
                            >
                                {{ getTypeName(item.type) + getSubTypeName(item.sub_type) }}
                            </v-chip>
                        </td>
                        <!-- 小节名称 -->
                        <td class="td-line font-weight-medium">
                            <div class="active-name"
                                @click.stop="goto(item.section_id, item.sub_type)"
                            >
                                {{ item.name }}
                            </div>
                        </td>
                        <!-- 小节说明 -->
                        <td class="table-left">{{ item.desc }}</td>
                        <!-- 是否可见 -->
                        <td>
                            <v-chip
                                label
                                small
                                class="font-weight-medium"
                                :color="item.is_show ? 'light-green' : 'light-blue darken-1'"
                                text-color="white"
                                @click="toggleIsShow(item)"
                            >
                                {{ item.is_show ? '学生' : '仅老师' }}
                            </v-chip>
                        </td>
                        <!-- <td>{{ item.status }}</td> -->
                        <!-- 操作 -->
                        <td v-if="course.is_private">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        class="mx-2"
                                        icon
                                        height="32"
                                        width="32"
                                        outlined
                                        x-small
                                        elevation="2"
                                        color="purple"
                                        @click.stop="openEditCourseSectionDlg(item)"
                                    >
                                        <v-icon dark> mdi-pencil </v-icon>
                                    </v-btn>
                                </template>
                                <span>编辑小节</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        class="mx-2"
                                        icon
                                        height="32"
                                        width="32"
                                        outlined
                                        x-small
                                        elevation="2"
                                        color="blue darken-2"
                                        @click.stop="openConfirmDlg('duplicateSection', { cid: item.cid, sid: item.section_id })"
                                    >
                                        <v-icon dark> mdi-content-copy </v-icon>
                                    </v-btn>
                                </template>
                                <span>复制小节</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        class="mx-2"
                                        icon
                                        height="32"
                                        width="32"
                                        outlined
                                        x-small
                                        elevation="2"
                                        color="green"
                                        @click.stop="moveSection({ cid: item.cid, sid: item.section_id })"
                                    >
                                        <v-icon dark> mdi-file-move-outline </v-icon>
                                    </v-btn>
                                </template>
                                <span>移动小节</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs" v-on="on"
                                        class="mx-2"
                                        icon
                                        height="32"
                                        width="32"
                                        outlined
                                        x-small
                                        elevation="2"
                                        color="red darken-1"
                                        @click.stop="openConfirmDlg('delSection', { id: item.section_id })"
                                    >
                                        <v-icon dark> mdi-trash-can </v-icon>
                                    </v-btn>
                                </template>
                                <span>删除小节</span>
                            </v-tooltip>
                        </td>
                        <td v-else>
                            <v-btn
                                tile
                                dark
                                color="orange"
                                class="font-weight-bold text-body-1"
                                style="margin: 0;"
                                max-height="36"
                                @click.stop="goto(item.section_id, item.sub_type)"
                            >
                                <v-icon left> mdi-eye </v-icon>
                                查看
                            </v-btn>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-data-table>
        
        <!-- 编辑课程 -->
        <EditCourseDlg ref="editCourseDlg" @submit="submitCourse" />

        <!-- 分享课程 -->
        <ShareCourseDlg ref="shareCourseDlg" />

        <!-- 课程上线确认 -->
        <ConfirmDlg
            ref="onlineConfirmDlg"
            title="课程上线"
            text="确认 上线 当前课程内容么？"
            keyword=" 上线 "
            @confirm="onlineConfirm"
        />
        <!-- 课程下线确认 -->
        <ConfirmDlg
            ref="offlineConfirmDlg"
            title="课程下线"
            text="确认 下线 当前课程内容么？"
            keyword=" 下线 "
            @confirm="offlineConfirm"
        />
        <!-- 课程删除确认 -->
        <ConfirmDlg
            ref="delGroupCourseConfirmDlg"
            title="删除课程"
            text="确认 删除 当前课程么？"
            keyword=" 删除 "
            @confirm="delCourseConfirm"
        />

        <!-- 编辑小节 -->
        <EditSectionDlg ref="editCourseSectionDlg" @submit="submitSection" />
        <!-- 移动小节 -->
        <MoveSectionToDlg ref="moveToDlg" @submit="submitMoveTo" />
        <!-- 复制小节 -->
        <ConfirmDlg 
            ref="duplicateSectionConfirmDlg"
            title="复制小节"
            text="确认 复制 当前小节么？"
            keyword=" 复制 "
            @confirm="duplicateSectionConfirm"
        />
        <!-- 删除小节确认 -->
        <ConfirmDlg 
            ref="delSectionConfirmDlg"
            title="删除小节"
            text="确认 删除 当前小节么？"
            keyword=" 删除 "
            @confirm="delSectionConfirm"
        />

    </v-container>
</template>

<script>
import draggable from "vuedraggable";
import EditCourseDlg from "@/views/components/dialog/EditCourseDlg";
import ShareCourseDlg from "@/views/components/dialog/ShareCourseDlg";
import EditSectionDlg from "@/views/components/dialog/EditSectionDlg";
import ConfirmDlg from "@/views/components/dialog/ConfirmDlg";
import MoveSectionToDlg from "@/views/components/dialog/MoveSectionToDlg";
import {
    getAdminCourse,
    getAdminSectionList,
    editAdminCourse,
    onlineCourse,
    offlineCourse,
    editAdminSection,
    delAdminSection,
    duplicateAdminSection,
    moveAdminSection,
    setAdminSectionShow,
    updateAdminSectionListIndex,
    delAdminCourse,
} from "@/api/admin";
import { 
    getTypeColor, 
    gotoSection, 
    getTypeName, 
    getSubTypeName, 
    getSectionTypeColor,
    getCourseInfo
} from '@/utils/common';
import { getText, getImgUrl, getTypeFirstLetter } from "@/utils/util";

export default {
    name: "Course",

    data() {
        return {
            groupId: "",
            cid: "",
            course: {},

            defaultImg: {
                scratch: "35545445d836064a7fd834c58ce4",
                python: "e3db075709616499fe79760c35cd",
                robot: "8740291eaeafea6bf72e96571d63",
            },

            // 课程小节列表
            sections: [],
            sectionHeader: [
                {
                    text: "顺序",
                    value: "order",
                    sortable: false,
                    align: "center",
                    width: 60,
                },
                {
                    text: "序号",
                    value: "index",
                    sortable: false,
                    align: "center",
                    width: 60,
                },
                {
                    text: "类型",
                    value: "type",
                    align: "center",
                    sortable: false,
                    width: 120,
                },
                { 
                    text: "小节名称",
                    value: "name", 
                    align: "center",
                    sortable: false,
                },
                {
                    text: "小节说明",
                    value: "desc", 
                    align: "left",
                    sortable: false,
                },
                {
                    text: "设置是否可见",
                    value: "is_show",
                    sortable: false,
                    align: "center",
                    width: 120,
                },
                // {
                //     text: "状态",
                //     value: "status",
                //     sortable: false,
                //     align: "center",
                // },
                {
                    text: "操作",
                    value: "operate",
                    sortable: false,
                    align: "center",
                    width: 225,
                },
            ],
            sectionHeaderReadonly: [
                {
                    text: "序号",
                    value: "index",
                    sortable: false,
                    align: "center",
                    width: 80,
                },
                {
                    text: "类型",
                    value: "type",
                    align: "center",
                    sortable: false,
                    width: 120,
                },
                { 
                    text: "小节名称",
                    value: "name", 
                    align: "center",
                    sortable: false,
                },
                {
                    text: "小节说明",
                    value: "desc", 
                    align: "left",
                    sortable: false,
                },
                {
                    text: "是否可见",
                    value: "is_show",
                    sortable: false,
                    align: "center",
                    width: 120,
                },
                {
                    text: "操作",
                    value: "operate",
                    sortable: false,
                    align: "center",
                    width: 225,
                },
            ],
            fromPath: '',
        };
    },
    created() {
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.id) {
            this.cid = this.$route.query.id;
        }
        if (this.$route.query.id) {
            this.groupId = this.$route.query.groupId;
        }
        
        let isFromIde = false
        if (this.fromPath.substring(0, '/editor/'.length) == '/editor/') {
            isFromIde = true
        }
        this.loadData()
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // 通过 vm 访问组件实例,将值传入fromPath
            vm.fromPath = from.path;
        });
    },
    methods: {
        getText,
        getTypeColor,
        getTypeFirstLetter,
        gotoSection,
        getTypeName,
        getSubTypeName,
        getSectionTypeColor,
        getCourseInfo,
        getImgUrl4Type(imgId, type) {
            if (imgId == "" || imgId == null || imgId == undefined) {
                if (type == "SCRATCH") {
                    return getImgUrl(this.defaultImg.scratch)
                } else if (type == "PYTHON") {
                    return getImgUrl(this.defaultImg.python)
                } else if (type == "ROBOT") {
                    return getImgUrl(this.defaultImg.robot)
                }
                return ""
            }
            return getImgUrl(imgId)
        },
        loadData() {
            // 获取课程信息
            getAdminCourse(this.cid).then((response) => {
                // console.log(response.data);
                this.course = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });

            // 获取课程小节列表
            getAdminSectionList(this.cid).then((response) => {
                // console.log(response.data);
                this.sections = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        openEditCourseDlg(course = null) {
            // console.log(course);
            this.$refs.editCourseDlg.openDlg(course);
        },
        openShareCourseDlg(type, id) {
            // console.log(course);
            this.$refs.shareCourseDlg.openDlg(type, id);
        },
        openEditCourseSectionDlg(section = null) {
            this.$refs.editCourseSectionDlg.openDlg(section);
        },
        openConfirmDlg(type, params = null) {
            switch (type) {
                case "online":
                    this.$refs.onlineConfirmDlg.openDlg();
                    break;
                case "offline":
                    this.$refs.offlineConfirmDlg.openDlg();
                    break;
                case "duplicateSection":
                    this.$refs.duplicateSectionConfirmDlg.openDlg(params);
                    break;
                case "delSection":
                    this.$refs.delSectionConfirmDlg.openDlg(params);
                    break;
                case "delCourse":
                    this.$refs.delGroupCourseConfirmDlg.openDlg(params);
                    break;
            }
        },
        onlineConfirm() {
            onlineCourse(this.course.cid).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "课程上线成功",
                    })

                    // 更新页面数据
                    this.course.status = 1

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "课程上线失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        offlineConfirm() {
            offlineCourse(this.course.cid).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "课程下线成功",
                    })
                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "课程下线失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        submitCourse(newCourse) {
            editAdminCourse({
                cid: newCourse.cid,
                name: newCourse.name,
                courseType: newCourse.courseType,
                desc: newCourse.desc,
                points: newCourse.points,
                level: newCourse.level,
                cover: newCourse.cover,
                groupId: newCourse.groupId,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "编辑课程成功",
                    })

                    // 更新页面数据
                    this.course = response.data

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "编辑课程失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        async delCourseConfirm(params) {
            // console.log(params)
            delAdminCourse({ groupId: this.groupId, cid: params.cid }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "删除课程成功",
                    })

                    // 删除当前课程包成功，返回上一页
                    this.goBack()

                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "删除课程失败",
                    })
                }
            }).catch(function (err) {
                console.log(err)
            })
        },
        submitSection(newSection) {
            editAdminSection({
                sid: newSection.id,
                cid: this.cid,
                name: newSection.name,
                type: newSection.type,
                subType: newSection.subType,
                desc: newSection.desc,
                content: newSection.content,
                blockScope: newSection.blockScope,
                index: newSection.index,
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.$store.dispatch("web/SetAlert", {
                        type: "success",
                        msg: "编辑小节成功",
                    });

                    // 更新页面数据
                    let result = response.data;
                    // console.log(result);
                    if (newSection.id == "") {
                        // 创建
                        this.sections.push(result);

                    } else {
                        // 修改
                        for (let i in this.sections) {
                            if (this.sections[i].section_id == newSection.id) {
                                this.sections[i].name = newSection.name;
                                this.sections[i].type = newSection.type;
                                this.sections[i].sub_type = newSection.subType;
                                this.sections[i].desc = newSection.desc;
                                this.sections[i].content = newSection.content;
                                this.sections[i].block_scope = newSection.blockScope;
                                this.sections[i].index = newSection.index;
                                break;
                            }
                        }
                    }
                } else {
                    this.$store.dispatch("web/SetAlert", {
                        type: "error",
                        msg: "编辑小节失败",
                    });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        duplicateSectionConfirm(params) {
            duplicateAdminSection({
                sid: params.sid,
                cid: params.cid
            }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.sections.length; i++) {
                        if (this.sections[i].section_id == params.sid) {
                            this.sections.splice(i + 1, 0, response.data)
                            break
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "复制小节成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "复制小节失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        moveSection(params) {
            this.$refs.moveToDlg.openDlg(params);
        },
        async submitMoveTo(params) {
            // console.log(params)
            let res = await moveAdminSection({ sid: params.sid, cid: params.cid, newCourseId: params.newCourseId })
            if (res.msg == "SUCCESS") {
                for (let i = 0; i < this.sections.length; i++) {
                    if (this.sections[i].section_id == params.sid) {
                        this.sections.splice(i, 1)
                        break
                    }
                }
                this.$store.dispatch("web/SetAlert", { type: "success", msg: "移动小节成功" });

            } else {
                this.$store.dispatch("web/SetAlert", { type: "error", msg: "移动小节失败" });
            }
        },
        delSectionConfirm(params) {
            delAdminSection({ sid: params.id, cid: this.cid }).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.sections.length; i++) {
                        if (this.sections[i].section_id == params.id) {
                            this.sections.splice(i, 1)
                            break
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除小节成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除小节失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        sectionListChange(e) {
            if (e.moved) {
                let oldIndex = e.moved.oldIndex
                let newIndex = e.moved.newIndex

                // 保存元素
                let tmp = this.sections[oldIndex]
                // 将元素从旧位置删除
                this.sections.splice(oldIndex, 1)
                // 将元素添加到新位置
                this.sections.splice(newIndex, 0, tmp)
            }

            this.updateAdminSectionListIndex()
        },
        async updateAdminSectionListIndex() {
            let sectionIds = []
            for (let i = 0; i < this.sections.length; i++) {
                sectionIds.push(this.sections[i].section_id)
            }

            await updateAdminSectionListIndex({
                id: this.cid,
                sectionIds: sectionIds
            })
        },
        clickRow(item) {
            console.log(item)
            this.goto(item.section_id, item.sub_type)
        },
        goto(sid, subType) {
            let urlType = this.course.is_private ? 'editor' : 'classroom'
            this.gotoSection(this.course.cid, sid, subType, urlType, 'push')
        },
        // 用于「开始上课」
        async gotoClassroom(cid) {
            // 加载上课信息状态
            await this.getCourseInfo(cid).then(res => {
                if (res.data && res.data.sections && res.data.sections.length > 0) {
                    let sid = res.data.sections[0].sid
                    // let sectionType = res.data.sections[0].type
                    let subType = res.data.sections[0].sub_type
                    this.gotoSection(this.course.cid, sid, subType, 'classroom', 'push')
                }
            })
        },
        toggleIsShow(item) {
            item.is_show = !item.is_show

            setAdminSectionShow({
                sid: item.section_id,
                cid: item.cid,
                isShow: item.is_show
            })
        },
        goBack() {
            history.go(-1);
        },
    },

    components: {
        draggable,
        EditCourseDlg,
        ShareCourseDlg,
        EditSectionDlg,
        ConfirmDlg,
        MoveSectionToDlg,
    },
};
</script>

<style lang="scss" scoped>
.alert-area {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
}
.handle {
    cursor: move;
    
}
.row-pointer {
    cursor: move;
}
// 去掉v-card默认的上下的margin
.v-card {
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.course-info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .course-desc {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .desc-col {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 20px;

            &.one {
                flex: 1;
            }
            &.two {
                flex: 2;
            }
        }
        .desc-row {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 35px;
            .desc-name {
                width: 70px;
                min-width: 70px;
            }
        }
        .text {
            text-align: left;
            font-weight: 300;
            font-size: 14px;
            white-space: nowrap;
        }
        .rating {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .operate {
        // margin: 0 20px;
    }
}
.table-row {
    cursor: pointer;
}
.table-left {
    text-align: left;
}
.table-center {
    text-align: center;
}
.td-line {
    text-align: center;
    display: flex;
    justify-content: center;
    height: unset !important;
    
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
}
.expendContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
</style>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
