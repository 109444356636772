<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent width="450">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold">增加小节</span>
                </v-card-title>
                <!-- <v-row justify="center"> -->
                <v-row>
                    <v-col cols="12" md="10" offset="1">
                        <form class="">
                            <v-text-field
                                label="小节名称"
                                v-model="name"
                                required
                                :error-messages="nameErrors"
                                :counter="30"
                                @input="$v.name.$touch()"
                                @blur="$v.name.$touch()"
                            ></v-text-field>
                            
                            <v-text-field
                                label="小节说明"
                                v-model="desc"
                                required
                                :error-messages="descErrors"
                                :counter="30"
                                @input="$v.desc.$touch()"
                                @blur="$v.desc.$touch()"
                            ></v-text-field>

                            <v-radio-group row v-model="type">
                                <v-radio key="1" label="讲义" value="SLIDE" ></v-radio>
                                <v-radio key="2" label="练习" value="COURSE" ></v-radio>
                                <!-- <v-radio key="3" label="视频" value="VIDEO" ></v-radio> -->
                            </v-radio-group>

                            <v-radio-group row v-model="subType" v-if="type == 'SLIDE'">
                                <v-radio key="1" label="PPT" value="PPT"></v-radio>
                                <v-radio key="2" label="Markdown" value="MD"></v-radio>
                                <v-radio key="3" label="视频" value="VIDEO" ></v-radio>
                                <v-radio key="4" label="pdf" value="PDF" ></v-radio>
                            </v-radio-group>
                            <v-radio-group row v-model="subType" v-else-if="type == 'COURSE'">
                                <v-radio key="1" label="Scratch" value="SCRATCH"></v-radio>
                                <v-radio key="2" label="Python" value="PYTHON"></v-radio>
                            </v-radio-group>
                            <v-radio-group row v-model="blockScope" v-if="type == 'COURSE' && subType == 'SCRATCH'">
                                <v-radio key="1" label="全部命令" value="ALL"></v-radio>
                                <v-radio key="2" label="重点命令" value="KEYPOINT"></v-radio>
                            </v-radio-group>
                        </form>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: "EditSectionDlg",

    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(30) },
        desc: { required },
    },

    data: function () {
        return {
            dialog: false,

            id: "",
            name: "",
            desc: "",
            type: "", //COURSE, SLIDE, VIDEO
            subType: "", //SCRATCH, PYTHON, MD, PPT, VIDEO
            blockScope: "", // KEYPOINT, ALL
            // videoUrl: "",
            content: "",
            index: 0,
        };
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.maxLength && errors.push("名称超长");
            !this.$v.name.required && errors.push("需要填写小节名称");
            return errors;
        },
        descErrors() {
            const errors = [];
            if (!this.$v.desc.$dirty) return errors;
            !this.$v.desc.required && errors.push("需要填写小节说明");
            return errors;
        },
    },
    created() {},
    activated() {},
    methods: {
        openDlg(section = null) {
            if (section) {
                this.id = section.section_id
                this.name = section.name
                this.desc = section.desc
                this.type = section.type
                this.subType = section.sub_type
                this.content = section.content
                this.blockScope = section.block_scope
                this.index = section.index

            } else {
                this.clear();
            }
            this.dialog = true;
        },
        clear() {
            this.$v.$reset()

            this.id = ""
            this.name = ""
            this.desc = ""
            this.type = ""
            this.subType = ""
            this.content = ""
            this.blockScope = ""
            this.index = 0
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                // 用户确认后调用submit回调，将新的参数传递出去
                this.$emit("submit", {
                    id: this.id,
                    name: this.name,
                    type: this.type,
                    subType: this.subType,
                    desc: this.desc,
                    content: this.content,
                    blockScope: this.blockScope,
                    index: this.index,
                })
                this.dialog = false
            }
        },
    },
    components: {},
};
</script>
